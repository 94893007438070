import { useAuth } from '../context/AuthContext';
import { useOnce } from '../utils/hooks/useOnce';
import { getSessionId } from '../utils/session';

declare global {
  interface Window {
    clarity: (command: string, ...args: string[]) => void;
  }
}

export const ClaritySession: React.FC = () => {
  const { currentUser } = useAuth();
  useOnce(() => {
    if (!currentUser || typeof window.clarity === 'undefined') return;

    const sessionId = getSessionId();
    if (sessionId) {
      window.clarity('identify', currentUser.id, sessionId);
    }
  }, !!currentUser);

  return null;
};
