import { uuid } from '@leland-dev/leland-ui-library';

import { CLARITY_SESSION_ID_KEY } from './constants';

export const getSessionId = (): string => {
  if (typeof window === 'undefined') {
    return '';
  }

  let sessionId = window.sessionStorage.getItem(CLARITY_SESSION_ID_KEY);
  if (!sessionId) {
    sessionId = uuid();
    window.sessionStorage.setItem(CLARITY_SESSION_ID_KEY, sessionId);
  }
  return sessionId;
};
