import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { isSubscriptionOperation } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import merge from 'deepmerge';
import { createClient } from 'graphql-ws';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';

import generatedIntrospection from '../__generated-gql-types__/fragments';
import { isServer } from '../utils/constants';
import {
  IMPERSONATED_USER_KEY,
  LelandImpersonation,
} from '../utils/impersonation';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const LELAND_API_URL = process.env.NEXT_PUBLIC_LELAND_API_URL;
const LELAND_WSS_URL = process.env.NEXT_PUBLIC_LELAND_WSS_URL;
if (!LELAND_API_URL) {
  throw new Error('Missing env var: NEXT_PUBLIC_LELAND_API_URL');
}
if (!LELAND_WSS_URL) {
  throw new Error('Missing env var: NEXT_PUBLIC_LELAND_WSS_URL');
}

const wsLink = isServer
  ? null
  : new GraphQLWsLink(
      createClient({
        url: `${LELAND_WSS_URL}/graphql`,
        lazy: true,
        connectionAckWaitTimeout: 30000,
        keepAlive: 30000,
      }),
    );

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const uploadLink = createUploadLink({
  uri: `${LELAND_API_URL}/graphql`,
  credentials: 'include', // necessary for local development
}) as ApolloLink;

const headerLink = setContext((_, { headers }) => {
  let impersonatedUserId: Nullable<string>;
  try {
    impersonatedUserId =
      LelandImpersonation.getImpersonatedUserIdFromBrowserCookie();
  } catch {
    impersonatedUserId = null;
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // return the headers to the context so httpLink can read them
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      ...(impersonatedUserId
        ? { [IMPERSONATED_USER_KEY]: impersonatedUserId }
        : {}),
      'x-leland-timezone': timezone,
    },
  };
});

const typenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
  return forward(operation);
});

const omitTypename = <T>(key: string, value: T): Optional<T> => {
  return key === '__typename' ? undefined : value;
};

const httpsLink = ApolloLink.split(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  (o) => o.getContext().hasUpload,
  uploadLink,
  ApolloLink.from([typenameMiddleware, uploadLink]),
);

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: headerLink.concat(
      isServer || !wsLink
        ? httpsLink
        : ApolloLink.split(
            ({ query }) => {
              return isSubscriptionOperation(query);
            },
            wsLink,
            httpsLink,
          ),
    ),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }),
  });
};

export const initializeApollo = (
  initialState: Nullable<NormalizedCacheObject> = null,
): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: <T = unknown>(destinationArray: T[], sourceArray: T[]) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = <TPageProps>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: {
    props: TPageProps;
  },
): {
  props: TPageProps;
} => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useApollo = <TPageProps extends Record<string, unknown>>(
  pageProps: TPageProps,
): ApolloClient<NormalizedCacheObject> => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo(state as Nullable<NormalizedCacheObject>),
    [state],
  );
  return store;
};
